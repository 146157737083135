import React, {useEffect, useState} from "react";
import useGet from "api/useGet";
import {useNavigate, useParams} from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../../component/order/detail";
import {ChkBox, CustomSelect, InputItemBox, TextAreaItem} from "../../../component/basic/formItems";
import {PhoneSetting} from "../../../js/function";
import * as fatchSet from "../../../api/api";

const Exchange = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();

    const [update, setUpdate] =  useState(1);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);
    const [originalOrder, setOriginalOrder] =  useState('');
    const [reason, setReason] =  useState('');
    const [reasonDetail, setReasonDetail] =  useState('');

    const [addressCheck, setAddressCheck] =  useState(false);
    const [deliveryName, setDeliveryName] =  useState('');
    const [deliveryPhone, setDeliveryPhone] =  useState('');
    const [deliveryAddress, setDeliveryAddress] =  useState('');
    const [deliveryAddressDetail, setDeliveryAddressDetail] =  useState('');
    const [deliveryPostcode, setDeliveryPostcode] =  useState('');

    const orderApi = useGet({url: `/order/detail/product?orderProductId=${id}`, loginType:"login"});
    const addressApi = useGet({url: `/address?update=${update}`, loginType:"login"});

    useEffect(() => {
        if (orderApi) {
            setOriginalOrder(orderApi)
        }
    }, [orderApi]);

    function handleStatus() {
        if ((!deliveryName || !deliveryPhone || !deliveryAddress || !deliveryAddressDetail || !deliveryPostcode)) {
            setBottomMsgData({
                text : "배송지를 입력해주세요",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        let payload = {
            type: 'exchange',
            orderProductId: parseInt(id),
            reason: reason,
            reasonDetail: reasonDetail,
            name: deliveryName,
            phone: deliveryPhone,
            postcode: deliveryPostcode,
            address: deliveryAddress,
            addressDetail: deliveryAddressDetail,
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/return-request/register`,
            success: (result) => {
                setPopupData({
                    addClass:null,
                    title:"교환 접수 완료",
                    text:`교환 접수 신청이 완료되었습니다.<br/>접수된 교환 신청 건은 1~3 영업일 내로<br/>담당자가 확인 후 처리드릴 예정입니다.`,
                    closeType:true,
                    closeFunc:(e) => {setPopupData(null)},
                    btnFunc0:(e)=>{setPopupData(null); navigate('/mypage/order')},
                    btn0:"확인",
                    btn0Type:"",
                    confirm:true
                });
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="교환 접수"
                centerTitle={true}
                homeBtn={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="orderDetailBox">
                    <OrderData products={[originalOrder]} orderDetail={true} refund={true} setBottomMsgData={setBottomMsgData}/>
                </div>
                <div className="orderHr"/>
                <div className="inputContentsBox">
                    <div className="inputTitle">교환 접수 내용</div>
                    <div className="inputItem">
                        <div className="title">교환 사유</div>
                        <div className="contents">
                            <CustomSelect
                                placeholder="교환 사유를 선택하세요"
                                value={reason}
                                options={[
                                    {'id': '단순 변심', 'value': '단순 변심'},
                                    {'id': '상품의 파손 또는 불량', 'value': '상품의 파손 또는 불량'},
                                    {'id': '상품 출고 또는 배송 지연', 'value': '상품 출고 또는 배송 지연'},
                                    {'id': '다른 상품 오배송 또는 구성품 누락', 'value': '다른 상품 오배송 또는 구성품 누락'},
                                ]}
                                func={(value, id) => { setReason(id) }}
                                valKey="id"
                                nameKey="value"/>
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">교환 상세 사유</div>
                        <div className="contents">
                            <TextAreaItem
                                inputName=""
                                placeholder="교환을 신청하시는 이유를 최대한 상세하게 작성해주세요."
                                max={500}
                                maxChk={false}
                                value={reasonDetail}
                                func={(e) => {setReasonDetail(e)}}
                            />
                        </div>
                    </div>
                </div>
                <div className="orderHr"/>
                <div className="inputContentsBox">
                    <div className="inputTitle">
                        <div className="left">수거지 정보 입력</div>
                        <div className="right">
                            <ChkBox label={''}
                                    checkedType={addressCheck}
                                    func={() => {
                                        if (!addressCheck) {
                                            setDeliveryName(addressApi[0]?.name)
                                            setDeliveryPhone(addressApi[0]?.phone)
                                            setDeliveryPostcode(addressApi[0]?.postcode)
                                            setDeliveryAddress(addressApi[0]?.address)
                                            setDeliveryAddressDetail(addressApi[0]?.addressDetail)
                                        } else {
                                            setDeliveryName('')
                                            setDeliveryPhone('')
                                            setDeliveryPostcode('')
                                            setDeliveryAddress('')
                                            setDeliveryAddressDetail('')
                                        }
                                        setAddressCheck(!addressCheck);
                                    }}/>
                            기존 배송지와 동일
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">이름</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="이름을 입력해주세요."
                                value={deliveryName}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryName(value) }}
                            />
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">휴대폰 번호</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="휴대폰 번호를 입력해주세요."
                                value={PhoneSetting(deliveryPhone)}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryPhone(value) }}
                            />
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">배송지</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="주소 검색"
                                value={deliveryAddress}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryAddress(value) }}
                            />
                        </div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="상세 주소 입력"
                                value={deliveryAddressDetail}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryAddressDetail(value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox pb-20">
                    <div className="informationBox">
                        <div className="title">교환 시 주의사항</div>
                        <div className="contentsBox">
                            <div className="contents">
                                · 함량 부적합, 용량 부족, 부패 및 변질, 유통·유효 기간의 경과, 이물혼입, 품질 불량의 상태인 경우 교환 접수가 가능합니다.
                            </div>
                            <div className="contents">
                                · 상품 자체의 결함 및 하자로 판명된 경우와 배송 중 발생한 결함 및 하자로 판명된 경우 동일 상품으로 교환 가능합니다.
                            </div>
                            <div className="contents">
                                · 동일 상품 교환이 불가능할 경우, 유사 상품(가격 대비 1:1)으로 교환해드립니다.
                            </div>
                        </div>
                    </div>
                    <button type="button" className={`btn_popup full`} onClick={() => { handleStatus() }}>교환 접수 신청</button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup data={popupData}/>
        </>
    );
};

export default Exchange;