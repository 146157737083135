import React from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";

const PresentFail = (props) => {
    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="선물 안내"
                centerTitle={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="resultBox">
                    <div className="resultTitle">만료된 선물입니다.</div>
                    <div className="resultContents">받으신 선물이 주문 취소되었거나,<br/>
                        유효기간이 만료되었습니다.
                    </div>
                </div>
                <div className="resultImageBox">
                    <img src="/assets/images/icon/deliveryFail.svg"/>
                </div>
            </ContentsSection>
        </>
    );
};

export default PresentFail;