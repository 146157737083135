import React from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";

const PresentComplete = (props) => {
    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="배송 신청 완료"
                centerTitle={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="resultBox">
                    <div className="resultTitle">배송 신청 완료!</div>
                    <div className="resultContents">팜픽이 곧 배송해드릴게요.<br/>
                        제품에 대한 배송 현황은 문자 또는 카카오<br/>
                        알림톡 메시지를 통해 확인 가능합니다.</div>
                </div>
                <div className="resultImageBox">
                    <img src="/assets/images/icon/deliveryComplete.svg"/>
                </div>
            </ContentsSection>
        </>
    );
};

export default PresentComplete;