import React, {useCallback, useEffect, useRef, useState} from "react";
import useGet from "api/useGet";
import {useNavigate, useParams} from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../component/order/detail";
import {BtnBox} from "../../component/basic/btns";
import {comFormat, PhoneSetting} from "../../js/function";
import moment from "moment/moment";

const Complete = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();

    const [update, setUpdate] =  useState(1);
    const [originalOrder, setOriginalOrder] =  useState('');
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);
    const [popupData2,setPopupData2] =  useState(null);

    const orderApi = useGet({url: `/order/detail?orderId=${id}&type=default`, loginType:"login"});

    useEffect(()=>{
        window.Kakao.cleanup();
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_KEY);
        }
    },[]);

    useEffect(() => {
        if (orderApi) {
            setOriginalOrder(orderApi)
        }
    }, [orderApi]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="결제 완료"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                homeBtn={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="iconBox">
                    <img src="/assets/images/icon/popupConfirmIcon.svg"/>
                    <div className="title"><span>{originalOrder?.isPresent === 1 ? '선물' : originalOrder?.isRegular === 1 ? '정기배송' : ''} 결제</span>가 완료되었습니다.</div>
                    <div className="contents">
                        {originalOrder?.isPresent === 1 ? <>[선물 메시지 보내기]로 받는 분에게 마음을 보내보세요.<br/>
만약 메시지를 보내지 못했다면<br/>
주문내역에서 선물하기 메시지를 보낼 수 있어요.</> : <>구매 정보나 배송 현황을 확인하시려면<br/>
                        주문 내역을 통해서 확인해 주세요!</>}</div>
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox">
                    <OrderData type={originalOrder?.isPresent === 1 ? 'present' : ''} products={originalOrder?.orderProducts} orderPage={true} orderInfo={originalOrder} />
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox">
                    <div className="orderInfo">
                        <div className="orderTitle">{originalOrder?.isPresent === 1 ? '선물 수령자 정보' : '배송 정보'}</div>
                        <div className="orderContentsBox">
                            <div className="orderContents">
                                <div className="title">이름</div>
                                <div className="contents">{originalOrder?.recipientName ? originalOrder?.recipientName : '-'}</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">휴대폰 번호</div>
                                <div className="contents">{originalOrder?.recipientPhone ? PhoneSetting(originalOrder?.recipientPhone) : '-'}</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">배송지</div>
                                <div className="contents">{!originalOrder?.recipientAddress && !originalOrder?.recipientAddressDetail ? '-' : originalOrder?.recipientAddress + ' ' + originalOrder?.recipientAddressDetail}</div>
                            </div>
                        </div>
                    </div>
                    {originalOrder?.isPresent === 1 && <div className="subContents">연락처와 배송지 정보는 선물 받는 분이 직접 입력해요.</div>}
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox" style={{ paddingBottom: 100 }}>
                    <div className="orderInfo">
                        <div className="orderTitle">구매 정보</div>
                        <div className="orderContentsBox">
                            {originalOrder?.isRegular === 1 && (
                                <div className="orderContents">
                                    <div className="title">배송받는 주기</div>
                                    <div className="contents">매 월 {originalOrder?.deliveryDay}일</div>
                                </div>
                            )}
                            <div className="orderContents">
                                <div className="title">상품 금액</div>
                                <div className="contents">{comFormat(originalOrder?.paymentPrice - originalOrder?.deliveryPrice - originalOrder?.usePoint)}원</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">배송비</div>
                                <div className="contents">{comFormat(originalOrder?.deliveryPrice)}원</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">포인트 할인</div>
                                <div className="contents">- {comFormat(originalOrder?.usePoint)}원</div>
                            </div>
                        </div>
                        <div className="orderTotal">
                            <div className="title">총 결제금액</div>
                            <div className="contents">{comFormat(originalOrder?.paymentPrice)}원</div>
                        </div>
                    </div>
                </div>
                <BtnBox boxType="fixed" addClass={`indexUp fixButtonBox`}>
                    {originalOrder?.isPresent === 1 ? (<button className="fixButton full" onClick={() => {
                        window.Kakao.Share.sendCustom({
                            templateId: 110302,
                            templateArgs: {
                                name: `${originalOrder?.userName}`,
                                id: id
                            },
                        });
                    }}>선물 메시지 보내기</button>) : (
                        <>
                            <button className="fixButton" onClick={() => navigate('/mypage/order')}>주문 내역</button>
                            <button className="fixButton full" onClick={() => navigate('/')}>쇼핑 더하기</button>
                        </>
                    )}
                </BtnBox>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup data={popupData}/>
            <Popup data={popupData2}/>
        </>
    );
};

export default Complete;