import React, { useState } from "react";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {comFormat} from "../../js/function";

const Index = (props) => {
    const navigate = useNavigate();

    const [update, setUpdate] =  useState(1);
    const [category, setCategory] =  useState('');
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const userApi = useGet({url: `/user/select-userInfo`, loginType:"login"});
    const cartCountApi = useGet({url: `/cart/count`, loginType:"login"});

    return (
        <>
            <Header
                addClass="pageMainBg"
                pageBack={true}
                func={() => {navigate(-1)}}
                cartCount={cartCountApi}
                homeBtn={true}
                cartBkBtn={true}
                color="black"
            />
            <ContentsSection addClass="headerContents pageMainBgGr">
                <div className="mypageProfileBox">
                    {userApi?.gender === 'male' && (<img src="/assets/images/basic/male.png"/>)}
                    {userApi?.gender === 'female' && (<img src="/assets/images/basic/female.png"/>)}
                    <div className="buttonBox">
                        <div className="nickname">{userApi?.nickname} 님</div>
                        {/*<div>
                            <button className="fitButton">내 정보 수정 ></button>
                        </div>*/}
                    </div>
                </div>
                <div className="mypageMenuBox">
                    <button className="mypageMenu" onClick={() => navigate('/mypage/order')}>
                        주문 내역
                        <img src="/assets/images/basic/right-button-gray.svg"/>
                    </button>
                    <button className="mypageMenu" onClick={() => navigate('/point')}>
                        포인트 내역
                        <img src="/assets/images/basic/right-button-gray.svg"/>
                    </button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Index;