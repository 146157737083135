import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {BottomErrMsg} from "../../component/basic/popup";

const Fail = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [check,setCheck] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const message = queryParams.get("message");

    useEffect(() => {
        if (!check) {
            setCheck(true)
            setBottomMsgData({
                text : message,
                chk : bottomMsgData.chk + 1
            });

            setTimeout(() => {
                navigate('/')
            }, 3000);
        }
    }, [check]);


    return <div>
        <BottomErrMsg
            text={bottomMsgData.text ? bottomMsgData.text : ""}
            chk={bottomMsgData.chk}
        />
    </div>
};

export default Fail;