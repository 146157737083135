import React, {useState} from "react";
import useGet from "api/useGet";
import {useNavigate, useParams} from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import {ReviewData} from "../../component/review/detail";

const Index = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();

    const [update, setUpdate] =  useState(1);

    const reviewApi = useGet({url: `/review?registrationNumber=${id}&update=${update}`, loginType:"login"});

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                headTitle={reviewApi?.length + '건의 리뷰'}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="productDetailBox mbt-20">
                    <div className="productDetailContentsBox">
                        <div className="reviewBox">
                            {reviewApi?.length > 0 && reviewApi?.map((review) => (
                                <ReviewData review={review} func={(e) => setUpdate(update + 1)}/>
                            ))}
                        </div>
                    </div>
                </div>
            </ContentsSection>
        </>
    );
};

export default Index;