import React from "react";
import { useState, useRef } from "react";
import { useCallback } from "react";
import {BottomErrMsg, Popup} from "../basic/popup";
import {useNavigate} from "react-router-dom";
import {comFormat} from "../../js/function";
import * as fatchSet from "../../api/api";
import moment from "moment";
import useGet from "../../api/useGet";

function OrderData(data){
    const navigate = useNavigate();
    const box = useRef(null);

    const [popupData,setPopupData] =  useState(null);
    const [popupData2,setPopupData2] =  useState(null);
    const [bottomOpen,setBottomOpen] = useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [cancelProducts, setCancelProducts] =  useState([]);

    const [deliveryCode, setDeliveryCode] =  useState('');
    const [deliverNumber, setDeliveryNumber] =  useState('');

    const deliverApi = useGet({url: deliverNumber && deliveryCode ? `/delivery/track?carrierId=${deliveryCode}&trackId=${deliverNumber}` : null, loginType:"login"});
    const originalDeliveryData = deliverApi?.data ? deliverApi?.data?.track?.events?.edges : null;

    const onClickEvn = () =>{
        setBottomOpen(!bottomOpen);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setBottomOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [bottomOpen]
    );

    function handleStatus(id, status, type = 'orderProduct') {
        let payload = {};
        if (type === 'orderProduct') {
            payload = {
                status: status,
                orderProductId: id,
            };
        } else {
            payload = {
                status: status,
                orderId: id,
            };
        }

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/status-modify`,
            success: (result) => {
                data?.setUpdate(data?.update + 1)
                if (status === 10) {
                    setPopupData(null)
                    setPopupData2({
                        addClass:null,
                        title:"주문 취소 신청 완료",
                        text:`주문 취소 신청이 완료되었습니다.<br/>결제가 진행된 주문은 영업일 기준 최대 3~5일 소요되며 취소가 지연되실 경우 결제하신 카드사로 문의해주세요.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{setPopupData2(null)},
                        btn0:"닫기",
                        btn0Type:"line",
                        confirm:true
                    });
                } else if (status === 5) {
                    setPopupData(null)
                    setPopupData2({
                        addClass:null,
                        title:"리뷰 작성 안내",
                        text:`구매해 주셔서 감사합니다!<br/>지금 바로 리뷰를 작성해보시겠어요?<br/>리뷰 작성 시 소정의 포인트를 지급드려요.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{
                            setPopupData2(null);
                            if (data?.orderDetail) {
                                navigate(`/review/register/${data?.orderInfo?.id}?orderProductId=${data?.products[0]?.id}`)
                            } else {
                                navigate(`/mypage/order/detail/${data?.orderInfo?.id}`)
                            }
                        },
                        btn0:"리뷰 작성",
                        btn0Type:"",
                        btn1:"닫기",
                        btn1Type:"line",
                        btnFunc1:(e)=>{setPopupData2(null)},
                        confirm:true
                    });
                }
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message,
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleRegularCancel() {
        let payload = {
            orderId: data?.orderInfo?.id,
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/regular/cancel`,
            success: (data) => {
                const result = JSON.parse(data);
                setPopupData(null)
                if (result?.orderStatus !== 2 || result?.orderStatus !== 3) {
                    setPopupData2({
                        addClass:null,
                        title:"구독 종료 완료",
                        text:`구독 종료가 완료되었습니다.<br/>이용해주셔서 감사합니다.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e) => {setPopupData2(null)},
                        btn0:"확인",
                        btn0Type:"",
                        confirm:true
                    });

                } else {
                    setPopupData2({
                        addClass:null,
                        title:"구독 종료 예정",
                        text:`배송중이거나, 배송 준비중 상태의 상품이 있어 구독 종료가 예약되었습니다.<br/>다음 결제일인 ${result?.schedulePaymentDate}부터 결제 및 배송이 진행되지 않습니다.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{setPopupData2(null)},
                        btn0:"확인",
                        btn0Type:"",
                        confirm:true
                    });
                }
            },
            err: (result) => {
                if(result.data || result.message){
                    data?.setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <div className={`orderItem ${data?.orderPage ? 'nonLine' : ''}`}>
            <div className="orderTitle">{data?.present ? '선물 받은 상품' : data?.refund ? '대상 상품' : data?.orderPage ? '주문 상품' : data?.type === 'present' ? (data?.completePage ? '선물한 상품' : '선물할 상품') : data?.orderInfo ? (data?.orderInfo?.isReturn !== 1 ? getStatus(data?.products[0]?.status != 10 ? data?.products[0]?.status : 10, data?.orderInfo?.isRegular, data?.orderInfo?.regularStatus) : getReturnStatus((data?.products[0] ? data?.products[0]?.returnType : data?.products?.returnType), (data?.products[0] ? data?.products[0].status : data?.products.status))) : ''}</div>
            <div className="orderProductBox">
                {(data?.orderInfo && data?.products) && (
                    (data?.orderInfo?.isReturn === 0 || data?.products.length > 0)
                    ?
                        <>
                            {data?.products?.map((product) => (
                                <div className="orderProduct" onClick={() => { if (!data?.orderDetail) { navigate(`/mypage/order/detail/${data?.orderInfo?.id}?orderProductId=&type=${data?.orderInfo?.isCancel === 1 ? `cancel` : 'default'}`) } }}>
                                    <div className="orderImage">
                                        <img src={product?.image_url}/>
                                    </div>
                                    <div className="orderContents">
                                        <div className="title">{data?.orderInfo?.isRegular === 1 && <div className="label">정기배송</div>}{(data?.type === 'present' || data?.orderInfo?.isPresent === 1) && (<div className="label">선물</div>)} {product?.name + (!product?.productOptionName ? ' * ' + product?.amount : '')}</div>
                                        <div className="option">{product?.productOptionName ? (product?.productOptionName + ' * ' + product?.amount) : ''}</div>
                                        <div className="price">{comFormat(product?.price)}원</div>
                                    </div>
                                </div>
                            ))}
                        </>
                    :
                        <>
                            <div className="orderProduct" onClick={() => {
                                if (!data?.orderDetail) {
                                    navigate(`/mypage/order/detail/${data?.orderInfo?.id}?orderProductId=${data?.products?.id}&type=return`);
                                }
                            }}>
                                <div className="orderImage">
                                    <img src={data?.products?.image_url}/>
                                </div>
                                <div className="orderContents">
                                    <div className="title">{data?.products?.orderInfo?.isRegular === 1 && <div
                                        className="label">정기배송</div>}{(data?.products?.type === 'present' || data?.products?.orderInfo?.isPresent === 1) && (
                                        <div
                                            className="label">선물</div>)} {data?.products?.name + (!data?.products?.productOptionName ? ' * ' + data?.products?.amount : '')}</div>
                                    <div
                                        className="option">{data?.products?.productOptionName ? (data?.products?.productOptionName + ' * ' + data?.products?.amount) : ''}</div>
                                    <div className="price">{comFormat(data?.products?.price)}원</div>
                                </div>
                            </div>
                        </>
                )}
                {(data?.reviewPage || data?.refund) && (
                    <>
                        {data?.products?.map((product) => (
                            <div className="orderProduct" onClick={() => { if (!data?.orderDetail) {  data?.orderInfo ? navigate(`/mypage/order/detail/${data?.orderInfo?.id}`) : navigate(`/mypage/order/detail/${data?.products?.id}`) } }}>
                                <div className="orderImage">
                                    <img src={product?.image_url}/>
                                </div>
                                <div className="orderContents">
                                    <div className="title">{data?.orderInfo?.isRegular === 1 && <div className="label">정기배송</div>}{(data?.type === 'present' || data?.orderInfo?.isPresent === 1) && (<div className="label">선물</div>)} {product?.name + (!product?.productOptionName ? ' * ' + product?.amount : '')}</div>
                                    <div className="option">{product?.productOptionName ? (product?.productOptionName + ' * ' + product?.amount) : ''}</div>
                                    <div className="price">{comFormat(product?.price)}원</div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {data?.options?.map((option) => (
                    <div className="orderProduct">
                        <div className="orderImage">
                            <img src={option?.productImageUrl}/>
                        </div>
                        <div className="orderContents">
                            <div className="title">{option?.isRegular === 1 && <div
                                className="label">정기배송</div>}{(data?.type === 'present' || data?.orderInfo?.isPresent === 1) && (
                                <div className="label">선물</div>)} {option?.productName + (!option?.name ? " * " + option?.qty : '')}</div>
                            {option?.name ? <div className="option">{option?.name} * {option?.qty}</div> : ''}
                            <div className="price">{comFormat(option?.total_price)}원</div>
                        </div>
                    </div>
                ))}
            </div>
            {data?.present && (
                <div className="presentBox">
                    <div className="presentTitle">선물 유효기간</div>
                    <div className="presentDate">{moment(data?.orderInfo?.limitDate).format("YYYY-MM-DD HH:mm")}까지</div>
                    <div className="presentContents">유효기간까지 배송 미신청 시, 주문 취소처리 됩니다.</div>
                </div>
            )}
            {(data?.products && !data?.refund && !data?.orderPage) && (
                <div className="orderButton">
                    {((data?.products[0]?.status === 0 || data?.products[0]?.status === 1) && data?.orderInfo?.isReturn !== 1 && data?.orderInfo?.isRegular !== 1) && (<button type="button" className={`btn_popup danger`} onClick={() => {
                        setPopupData({
                            addClass:null,
                            title:"주문 취소 신청",
                            text:`주문을 취소하시겠습니까?<br/>취소한 주문은 복구가 불가합니다.`,
                            closeType:true,
                            closeFunc:(e) => {setPopupData(null)},
                            btnFunc0:(e)=>{handleStatus(data?.orderDetail ? data?.products[0]?.id : data?.orderInfo?.id, 10, data?.orderDetail ? 'orderProduct' : 'order')},
                            btn0:"주문 취소",
                            btn0Type:"",
                            btn1:"취소",
                            btn1Type:"line",
                            btnFunc1:()=>{setPopupData(null);}
                        });
                    }}>주문 취소 신청</button>)}
                    {data?.products[0]?.isRegular !== 0 && data?.products[0]?.status === 4 && data?.orderInfo?.isReturn !== 1 && (
                        <button type="button" className={`btn_popup`} onClick={() => {
                            setPopupData({
                                addClass:null,
                                title:"구매 확정 안내",
                                text:`구매를 확정하시겠습니까?<br/>확정된 주문은 교환/환불이 불가합니다.`,
                                closeType:true,
                                closeFunc:(e) => {setPopupData(null)},
                                btnFunc0:(e)=>{handleStatus(data?.orderDetail ? data?.products[0]?.id : data?.orderInfo?.id, 5, data?.orderDetail ? "orderProduct" : "order")},
                                btn0:"구매 확정",
                                btn0Type:"",
                                btn1:"닫기",
                                btn1Type:"line",
                                btnFunc1:()=>{setPopupData(null);}
                            });
                        }}>구매 확정</button>
                    )}
                    {data?.products[0]?.status === 5 && data?.products[0]?.isReview === 0 && !data?.reviewPage && (
                        <button type="button" className={`btn_popup`} onClick={() => {
                            if (data?.orderDetail) {
                                navigate(`/review/register/${data?.orderInfo?.id}?orderProductId=${data?.products[0]?.id}`);
                            } else {
                                navigate(`/mypage/order/detail/${data?.orderInfo?.id}?orderProductId=&type=default`)
                            }
                        }}>리뷰 작성하기</button>
                    )}
                    {(data?.orderInfo?.isRegular === 1 && (data?.orderInfo?.regularStatus !== 10 || data?.orderInfo?.status < 5) && data?.orderDetail) && (
                        <button type="button" className={`btn_popup danger`} onClick={() => {
                            setPopupData({
                                addClass:null,
                                title:"구독 종료 안내",
                                text:`구독을 종료하시겠습니까?<br/>종료하시는 시점에 종료하고자 하시는 주문이 배송중이거나, 배송 준비중 상태일 경우 다음 회차의 결제부터 구독 종료됩니다.`,
                                closeType:true,
                                closeFunc:(e) => {setPopupData(null)},
                                btnFunc0:(e)=>{handleRegularCancel()},
                                btn0:"구독 종료",
                                btn0Type:"",
                                btn1:"닫기",
                                btn1Type:"line",
                                btnFunc1:()=>{setPopupData(null);}
                            });
                        }}>구독 종료</button>
                    )}
                    {(data?.products[0]?.status === 3 && data?.orderInfo?.isReturn !== 1) && (<button type="button" className={`btn_popup`} onClick={() => {
                        setDeliveryCode(data?.orderInfo?.deliveryCode)
                        setDeliveryNumber(data?.orderInfo?.deliveryNumber)
                        setBottomOpen(true)
                    }}>배송 조회</button>)}
                    {(data?.orderInfo?.isReturn === 1 && (data?.products[0]?.status === 5 || data?.products?.status === 5)) && (<button type="button" className={`btn_popup`} onClick={() => {
                        setDeliveryCode(data?.products?.returnDeliveryCode)
                        setDeliveryNumber(data?.products?.returnDeliveryNumber)
                        setBottomOpen(true)
                    }}>배송 조회</button>)}
                    {data?.products[0]?.status === 4 && data?.orderDetail && data?.orderInfo?.isReturn !== 1 && (
                        <div className="btnBox col2">
                            <button type="button" className={`btn_popup danger col2`} onClick={() => navigate(`/exchange/${data?.products[0]?.id}`)}>교환 접수</button>
                            <button type="button" className={`btn_popup danger col2`} onClick={() => navigate(`/refund/${data?.products[0]?.id}`)}>환불 접수</button>
                        </div>
                    )}
                    {(data?.orderInfo?.isPresent === 1 && data?.orderInfo?.status < 2 && data?.orderInfo?.isReturn !== 0) && (<button type="button" className={`btn_popup`} onClick={() => {
                        window.Kakao.Share.sendDefault({
                            objectType: "feed",
                            content: {
                                title: "팜픽에서 선물이 도착했어요!",
                                description: "OOO님이 친구분께 선물한 팜픽 제품을 지금 바로 확인해보세요. \n선물을 받으신 시점부터 5일이 지나게 되면 받으신 선물은 주문 취소처리 됩니다.",
                                imageUrl: process.env.REACT_APP_URL + "/assets/images/ogimg.png",
                                link: {
                                    mobileWebUrl: process.env.REACT_APP_URL + `/present/${data?.orderInfo?.id}`,
                                    webUrl: process.env.REACT_APP_URL + `/present/${data?.orderInfo?.id}`,
                                }
                            },
                            buttons: [
                                {
                                    title: "선물 확인하기",
                                    link: {
                                        mobileWebUrl: process.env.REACT_APP_URL + `/present/${data?.orderInfo?.id}`,
                                        webUrl: process.env.REACT_APP_URL + `/present/${data?.orderInfo?.id}`,
                                    }
                                }
                            ]
                        });
                    }}>선물 메시지 보내기</button>)}
                </div>
            )}
            <Popup data={popupData}/>
            <Popup data={popupData2}/>
            <div className={`bottom_optionBoxWrapper ${bottomOpen ? "active" : ""}`} ref={box}>
                <div className={`bottom_optionBox ${bottomOpen ? "active" : ""}`}>
                    <div className={`bottom_option`}>
                        <div className="title">배송 조회</div>
                        <div className={`bottom_option_close`} onClick={onClickEvn}>
                            <img src="/assets/images/basic/close.svg"/>
                        </div>
                    </div>
                    <div className="subTitleBox">
                        <div className="subTitle">
                            <div className="left">택배사</div>
                            <div className="right">{deliveryCode}</div>
                        </div>
                        <div className="subTitle">
                            <div className="left">운송장 번호</div>
                            <div className="right">{deliverNumber}</div>
                        </div>
                    </div>
                    <div className="historyBox">
                        {originalDeliveryData && (
                            originalDeliveryData?.map((data, key) => (
                                <div className="history">
                                    <div className="dot"><img src="/assets/images/icon/dot.svg"/></div>
                                    <div className="contentsBox">
                                        <div className="title">{data?.node?.status?.name}</div>
                                        <div className="contents">
                                            {data?.node?.description}
                                        </div>
                                        <div className="time">{data?.node?.time}</div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function getStatus(status, isRegular, regularStatus)
{
    if (isRegular === 1 && regularStatus === 10 && status >= 5) {
        return "구독종료"
    } else if (status === 0) {
        return '결제 대기'
    } else if (status === 1) {
        return '결제 완료'
    } else if (status === 2) {
        return '배송 준비 중'
    } else if (status === 3) {
        return '배송 중'
    } else if (status === 4) {
        return '배송 완료'
    } else if (status === 5) {
        return '구매 확정'
    } else if (status === 10) {
        return '결제 취소'
    } else {
        return status
    }
}


function getReturnStatus(type, status)
{
    if (status === 0) {
        return (type === 'refund' ? "환불 " : "교환 ") + '접수'
    } else if (status === 1) {
        return (type === 'refund' ? "환불 " : "교환 ") + '반송 중비중'
    } else if (status === 2) {
        return (type === 'refund' ? "환불 " : "교환 ") + '반송 중'
    } else if (status === 3) {
        return (type === 'refund' ? "환불 " : "교환 ") + '반송 완료'
    } else if (status === 4) {
        return (type === 'refund' ? "환불 " : "교환 ") + '배송 준비 중'
    } else if (status === 5) {
        return (type === 'refund' ? "환불 " : "교환 ") + '배송중'
    } else if (status === 6) {
        return (type === 'refund' ? "환불 " : "교환 ") + '완료'
    } else {
        return '-'
    }
}

export {OrderData};