import React, {useEffect, useState} from "react";
import useGet from "api/useGet";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../component/order/detail";
import {ChkBox, CustomSelect, InputItemBox, TextAreaItem} from "../../component/basic/formItems";
import {Swiper, SwiperSlide} from "swiper/react";
import * as fatchSet from "../../api/api";

const Register = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();
    const locationSearch = useLocation().search;

    const prams = locationSearch.split('&') ? locationSearch.split('&') : [];
    const orderProductId = prams[0] ? prams[0].split('?orderProductId=')[1] : ''

    const [update, setUpdate] =  useState(1);
    const [images, setImages] =  useState([]);
    const [star, setStar] =  useState(0);
    const [contents, setContents] =  useState('');
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);
    const [originalOrder, setOriginalOrder] =  useState('');

    const orderApi = useGet({url: `/order/detail?orderId=${id}&type=default${orderProductId !== '' ? `&orderProductId=${orderProductId}` : ''}`, loginType:"login"});

    useEffect(() => {
        if (orderApi) {
            setOriginalOrder(orderApi)
        }
    }, [orderApi]);

    const handleImage = (e) => {
        const files = e.target.files;
        let arr = [...images];

        for (const file of files) {
            if (arr.length < 5) {
                arr.push({
                    file: file,
                    preview_url: URL.createObjectURL(file),
                });
            } else {
                setBottomMsgData({
                    text : "사진은 5장까지 첨부할 수 있습니다.",
                    chk : bottomMsgData.chk + 1
                });
            }
        }

        setImages(arr);
    };

    function handleSubmit() {
        let formData = new FormData();
        formData.append("orderProductId", orderProductId);
        formData.append("grade", star);
        formData.append("text", contents);
        images?.map((image) => {
            formData.append("images", image?.file);
        })

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: formData,
            contentType: "none",
            loginType:"login",
            url: `/review/register`,
            success: (result) => {
                setUpdate(update + 1)
                setPopupData({
                    addClass:null,
                    title:"리뷰 작성 완료",
                    text:`리뷰 작성이 완료되었습니다!<br/>감사의 마음을 담아 포인트 500P가<br/>지급되었습니다.`,
                    closeType:true,
                    closeFunc:(e) => {setPopupData(null)},
                    btnFunc0:(e)=>{navigate(-1)},
                    btn0:"확인",
                    btn0Type:"",
                    confirm:true
                });
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="리뷰 작성"
                func={() => {navigate(-1)}}
                centerTitle={true}
                closeBtn={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="orderDetailBox">
                    <OrderData products={originalOrder?.orderProducts} reviewPage={true}/>
                </div>
                <div className="orderHr"/>
                <div className="inputContentsBox pb-20">
                    <div className="centerTitle">제품에 대한 평점을 남겨주세요</div>
                    <div className="starBox">
                        <div className="starItems">
                            <img src={`${star >= 1 ? '/assets/images/icon/starOnB.svg' : '/assets/images/icon/starOffB.svg'}`} onClick={() => setStar(1)}/>
                            <img src={`${star >= 2 ? '/assets/images/icon/starOnB.svg' : '/assets/images/icon/starOffB.svg'}`} onClick={() => setStar(2)}/>
                            <img src={`${star >= 3 ? '/assets/images/icon/starOnB.svg' : '/assets/images/icon/starOffB.svg'}`} onClick={() => setStar(3)}/>
                            <img src={`${star >= 4 ? '/assets/images/icon/starOnB.svg' : '/assets/images/icon/starOffB.svg'}`} onClick={() => setStar(4)}/>
                            <img src={`${star >= 5 ? '/assets/images/icon/starOnB.svg' : '/assets/images/icon/starOffB.svg'}`} onClick={() => setStar(5)}/>
                        </div>
                        <p>{star}점</p>
                    </div>
                    <div className="inputItem">
                        <div className="title">리뷰 내용 작성</div>
                        <div className="contents">
                            <TextAreaItem
                                inputName=""
                                placeholder="최대한 상세히 작성해주세요! 리뷰를 작성하시면 포인트를 지급드려요."
                                max={500}
                                maxChk={false}
                                value={contents}
                                func={(value) => {setContents(value)}}
                            />
                        </div>
                    </div>
                    <div className="imageUploadBox">
                        <div className="imageUploadTitle">
                            <div className="left">이미지 첨부</div>
                            <div className="right">
                                <span>{images.length}</span>
                                <span> / 5</span>
                            </div>
                        </div>
                        <div className="imageUploadContents">
                            <div className={"imageListBox"}>
                                {images?.map((item, i) => (
                                    <label className="uploadLabel">
                                        <img className="image" src={item?.preview_url}/>
                                        <img className="closeButton" src="/assets/images/icon/closeS.svg"
                                             onClick={() => {
                                                 let arr = [...images];
                                                 arr.splice(i, 1);
                                                 setImages(arr);
                                             }}/>
                                    </label>
                                ))}
                                <label className="uploadLabel">
                                    <img src="/assets/images/icon/image_upload.svg"/>

                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleImage}
                                    />
                                </label>

                            </div>
                            {/* <Swiper>
                                {images?.map((item, i) => (
                                    <SwiperSlide key={1}>
                                        <label className="uploadLabel">
                                            <img className="image" src={item?.preview_url}/>
                                            <img className="closeButton" src="/assets/images/icon/closeS.svg"
                                                 onClick={() => {
                                                     let arr = [...images];
                                                     arr.splice(i, 1);
                                                     setImages(arr);
                                                 }}/>
                                        </label>
                                    </SwiperSlide>
                                ))}
                                <SwiperSlide key={1}>
                                    <label className="uploadLabel">
                                        <img src="/assets/images/icon/image_upload.svg"/>

                                        <input
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            onChange={handleImage}
                                        />
                                    </label>
                                </SwiperSlide>
                            </Swiper>*/}
                        </div>
                    </div>
                    <button type="button" className={`btn_popup full`} onClick={() => {handleSubmit()}}>리뷰 작성</button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup data={popupData}/>
        </>
    );
};

export default Register;