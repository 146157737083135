import React, {useEffect, useState} from "react";
import useGet from "api/useGet";
import {useNavigate, useParams} from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../../component/order/detail";
import {ChkBox, CustomSelect, InputItemBox, TextAreaItem} from "../../../component/basic/formItems";
import {PhoneSetting} from "../../../js/function";
import * as fatchSet from "../../../api/api";

const Refund = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();

    const [update, setUpdate] =  useState(1);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);
    const [originalOrder, setOriginalOrder] =  useState('');
    const [reason, setReason] =  useState('');
    const [reasonDetail, setReasonDetail] =  useState('');

    const [addressCheck, setAddressCheck] =  useState(false);
    const [deliveryName, setDeliveryName] =  useState('');
    const [deliveryPhone, setDeliveryPhone] =  useState('');
    const [deliveryAddress, setDeliveryAddress] =  useState('');
    const [deliveryAddressDetail, setDeliveryAddressDetail] =  useState('');
    const [deliveryPostcode, setDeliveryPostcode] =  useState('');

    const orderApi = useGet({url: `/order/detail/product?orderProductId=${id}`, loginType:"login"});
    const addressApi = useGet({url: `/address?update=${update}`, loginType:"login"});

    useEffect(() => {
        if (orderApi) {
            setOriginalOrder(orderApi)
        }
    }, [orderApi]);

    function handleStatus() {
        if ((!deliveryName || !deliveryPhone || !deliveryAddress || !deliveryAddressDetail || !deliveryPostcode)) {
            setBottomMsgData({
                text : "배송지를 입력해주세요",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        let payload = {
            type: 'refund',
            orderProductId: parseInt(id),
            reason: reason,
            reasonDetail: reasonDetail,
            name: deliveryName,
            phone: deliveryPhone,
            postcode: deliveryPostcode,
            address: deliveryAddress,
            addressDetail: deliveryAddressDetail,
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/return-request/register`,
            success: (result) => {
                setPopupData({
                    addClass:null,
                    title:"환불 접수 완료",
                    text:`환불 접수 신청이 완료되었습니다.<br/>접수된 환불 신청 건은 1~3 영업일 내로<br/>담당자가 확인 후 처리드릴 예정입니다.`,
                    closeType:true,
                    closeFunc:(e) => {setPopupData(null)},
                    btnFunc0:(e)=>{setPopupData(null); navigate('/mypage/order')},
                    btn0:"확인",
                    btn0Type:"",
                    confirm:true
                });
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="환불 접수"
                centerTitle={true}
                homeBtn={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="orderDetailBox">
                    <OrderData products={[originalOrder]} orderDetail={true} refund={true} setBottomMsgData={setBottomMsgData}/>
                </div>
                <div className="orderHr"/>
                <div className="inputContentsBox">
                    <div className="inputTitle">환불 접수 내용</div>
                    <div className="inputItem">
                        <div className="title">환불 사유</div>
                        <div className="contents">
                            <CustomSelect
                                placeholder="환불 사유를 선택하세요"
                                value={reason}
                                options={[
                                    {'id': '단순 변심', 'value': '단순 변심'},
                                    {'id': '상품의 파손 또는 불량', 'value': '상품의 파손 또는 불량'},
                                    {'id': '상품 출고 또는 배송 지연', 'value': '상품 출고 또는 배송 지연'},
                                    {'id': '다른 상품 오배송 또는 구성품 누락', 'value': '다른 상품 오배송 또는 구성품 누락'},
                                ]}
                                func={(value, id) => { setReason(id) }}
                                valKey="id"
                                nameKey="value"/>
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">환불 상세 사유</div>
                        <div className="contents">
                            <TextAreaItem
                                inputName=""
                                placeholder="환불을 신청하시는 이유를 최대한 상세하게 작성해주세요."
                                max={500}
                                maxChk={false}
                                value={reasonDetail}
                                func={(e) => {setReasonDetail(e)}}
                            />
                        </div>
                    </div>
                </div>
                <div className="orderHr"/>
                <div className="inputContentsBox">
                    <div className="inputTitle">
                        <div className="left">수거지 정보 입력</div>
                        <div className="right">
                            <ChkBox label={''}
                                    checkedType={addressCheck}
                                    func={() => {
                                        if (!addressCheck) {
                                            setDeliveryName(addressApi[0]?.name)
                                            setDeliveryPhone(addressApi[0]?.phone)
                                            setDeliveryPostcode(addressApi[0]?.postcode)
                                            setDeliveryAddress(addressApi[0]?.address)
                                            setDeliveryAddressDetail(addressApi[0]?.addressDetail)
                                        } else {
                                            setDeliveryName('')
                                            setDeliveryPhone('')
                                            setDeliveryPostcode('')
                                            setDeliveryAddress('')
                                            setDeliveryAddressDetail('')
                                        }
                                        setAddressCheck(!addressCheck);
                                    }}/>
                            기존 배송지와 동일
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">이름</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="이름을 입력해주세요."
                                value={deliveryName}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryName(value) }}
                            />
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">휴대폰 번호</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="휴대폰 번호를 입력해주세요."
                                value={PhoneSetting(deliveryPhone)}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryPhone(value) }}
                            />
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">배송지</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="주소 검색"
                                value={deliveryAddress}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryAddress(value) }}
                            />
                        </div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="상세 주소 입력"
                                value={deliveryAddressDetail}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryAddressDetail(value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox pb-20">
                    <div className="informationBox">
                        <div className="title">환불 시 주의사항</div>
                        <div className="contentsBox">
                            <div className="contents">
                                · 반품으로 인한 환불 및 상품의 결함 또는 하자로 인한 환불(동일 상품 교환이 불가능하여 소비자가 환불을 원할 경우)의 경우 회사는 해당 상품을 수거하여 상품의 상태를 확인한 날로부터 3영업일 이내 환불 처리합니다.
                            </div>
                            <div className="contents">
                                · 미개봉 및 미사용 상태(제품 패키지의 실링 스티커가 뜯어이지 않은 상태)의 손상 및 변질이 없는 상품에 한해 반품이 가능하며 사용한 상품은 환불이 불가합니다.
                            </div>
                            <div className="contents">
                                · 프로모션, 패키지 상품을 반품하는 경우, 또는 사은품이 함께 지급된 상품을 반품하는 경우, 주문한 상품 외에 함께 제공된 증정 상품 및 사은품 등을 포함하여 배송 받은 모든 내용물을 함께 반환하여야 합니다.
                            </div>
                        </div>
                    </div>
                    <button type="button" className={`btn_popup full`} onClick={() => { handleStatus() }}>환불 접수 신청</button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup data={popupData}/>
        </>
    );
};

export default Refund;