import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import * as fatchSet from "../../api/api";
import {BottomErrMsg} from "../../component/basic/popup";

const Success = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const paymentType = queryParams.get("paymentType");
    const orderId = queryParams.get("orderId");
    const paymentKey = queryParams.get("paymentKey");

    const [check,setCheck] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    useEffect(() => {
        if (!check) {
            setCheck(true)
            let payload = {
                orderId: orderId?.replace('PHARMPICKORDER', ''),
            };

            fatchSet.FatchApi({
                type: "POST",
                formDataItem: JSON.stringify(payload),
                loginType: "login",
                url: `/order/payment-complete`,
                success: (result) => {
                    navigate(`/order/complete/${orderId?.replace('PHARMPICKORDER', '')}`)
                },
                err: (result) => {
                    if (result.data || result.message) {
                        setBottomMsgData({
                            text: result.message || "",
                            chk: bottomMsgData.chk + 1
                        });
                    }

                    setTimeout(() => {
                        navigate('/')
                    }, 3000);
                }
            })
        }
    }, [check]);

    return <div>
        <BottomErrMsg
            text={bottomMsgData.text ? bottomMsgData.text : ""}
            chk={bottomMsgData.chk}
        />
    </div>
};

export default Success;