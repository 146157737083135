import { Fragment } from "react";
import { CustomSelect } from "component/basic/formItems";
import { comFormat } from "js/function";
import CountInput from "./count";

function OptionSection(data){
    return (
        <>
            <ProductDetailInfo_subTextBox
                addClass=""
                name="옵션 선택"
                text={null}
            >
                <div className="optiopn_controllBox">
                    {(!data?.max || (data?.max > data?.optionArr?.length)) && (
                        <CustomSelect
                            addClass="notName productOptionSel"
                            inputName=""
                            placeholder="옵션을 선택해주세요."
                            value={null}
                            func={(name, id, price, amount, discountPercent, discountCut) => {
                                data.optionSelFunc(name, id, price, amount, discountPercent, discountCut);
                            }}
                            disabled={false}
                            valKey="id"
                            nameKey="name"
                            subValKey0="price"
                            subValKey1="amount"
                            subValKey2="discountPercent"
                            subValKey3="discountCut"
                            options={data.options}
                        />
                    )}
                    {data.optionArr && data.optionArr?.length > 0 ?
                        <ProductOptionSelBox>
                            {data.optionArr?.map((item,i)=>(
                                <OptionSelItem
                                    key={i}
                                    addClass=""
                                    countAddClass={data?.countAddClass}
                                    name={item.name}
                                    delFunc={()=>{data.optionDel(item.id)}}
                                    qty={item.qty}
                                    remain_amount={item.remain_amount}
                                    countFunc={(val)=>{data.countFunc(item,val)}}
                                    discount_basic_price={item?.discount_basic_price}
                                    discount_price={item?.discount_price}
                                    add_price={item.add_price}
                                    productName={item.productName}
                                />
                            ))}
                        </ProductOptionSelBox>
                    :""}
                </div>
            </ProductDetailInfo_subTextBox>
            <ProductDetailInfo_subTextBox
                addClass="paddingItem"
                name={null}
                text={null}
            >
                <div className="productDetailInfo_totalPriceBox">
                    <h3 className="productDetailInfo_totalPriceName">총 상품 금액</h3>
                    <div className="productDetailInfo_totalPriceItem">
                        {data.discount_totalPrice ?
                            <p className="productDetailInfo_totalBasicPrice" dangerouslySetInnerHTML={{__html:`${comFormat(data.totalPrice)}원`}}/>
                        :""}
                        <h2 className="productDetailInfo_totalPrice" dangerouslySetInnerHTML={{__html:`${data.discount_totalPrice ? comFormat(data.discount_totalPrice) : comFormat(data.totalPrice)}원`}}/>
                        {data?.regular && <span>/월</span>}
                    </div>
                </div>
            </ProductDetailInfo_subTextBox>
        </>
    );
}

function ProductItem(data) {
    return (
        <div className={`optionSelItem ${data.addClass || ""}`}>
            <div className="optionSelItem_nameBox">
                <h1 className="optionSelItem_name"
                    dangerouslySetInnerHTML={{__html: `${data.productName}`}}/>
            </div>
            <div className="optionSelItem_controll">
                <CountInput
                    addClass={data?.countAddClass}
                    val={data.qty}
                    qty={null}
                    optionQty={null}
                    maxQty={data.remain_amount}
                    func={data.countFunc}
                    excessFunc={() => {
                    }}
                />
                <div className="optionSelItem_priceBox">
                    {data.originalPrice ?
                        <p className="optionSelItem_basicPrice"
                           dangerouslySetInnerHTML={{__html: `${comFormat(data.originalPrice * data.qty)}원`}}/>
                        : ""}
                    <h2 className="optionSelItem_price"
                        dangerouslySetInnerHTML={{__html: `${data.discount_price ? comFormat(data.discount_price * data.qty) : comFormat(data.add_price * data.qty)}원`}}/>
                </div>
            </div>
        </div>
    )
}

function OptionSelItem(data) {
    return (
        <div className={`optionSelItem ${data.addClass || ""}`}>
            <div className="optionSelItem_nameBox">
                <h1 className="optionSelItem_name"
                    dangerouslySetInnerHTML={{__html: `${data.productName}<br/><span class="optionSelItem_optionName">${data.name}</span>`}}/>
                <button type="button" onClick={() => {
                    data.delFunc()
                }} className="optionSelItem_del">
                    <img src="/assets/images/basic/close.svg"/>
                </button>
            </div>
            <div className="optionSelItem_controll">
                <CountInput
                    addClass={data?.countAddClass}
                    val={data.qty}
                    qty={null}
                    optionQty={null}
                    maxQty={data.remain_amount}
                    func={data.countFunc}
                    excessFunc={() => {
                    }}
                />
                <div className="optionSelItem_priceBox">
                    {data.discount_price ?
                        <p className="optionSelItem_basicPrice"
                           dangerouslySetInnerHTML={{__html: `${comFormat(data.discount_basic_price * data.qty)}원`}}/>
                        : ""}
                    <h2 className="optionSelItem_price"
                        dangerouslySetInnerHTML={{__html: `${data.discount_price ? comFormat(data.discount_price * data.qty) : comFormat(data.add_price * data.qty)}원`}}/>
                </div>
            </div>
        </div>
    );
}

function ProductOptionSelBox(data){
    return (
        <div className={`productOptionSelBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function ProductDetailInfo_subTextBox(data){
    return (
        <div className={`productDetailInfo_subTextBox ${data.addClass||""}`}>
            <p className="productDetailInfo_subText" dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.text ? <p className="productDetailInfo_subVal" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
            {data.children}
        </div>
    );
}

export {OptionSection, ProductDetailInfo_subTextBox, ProductItem};