import React, {useState} from "react";
import useGet from "api/useGet";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import {BtnBox} from "../../component/basic/btns";
import {ChkBox} from "../../component/basic/formItems";
import CountInput from "../../component/product/count";
import {comFormat} from "../../js/function";
import * as fatchSet from "../../api/api";
import getDiscountPrice from "../../component/product/getDiscountPrice";

const Index = (props) => {
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(1);
    const [selectIds,setSelectIds] =  useState([]);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [submitChk,setSubmitChk] =  useState(true);

    const cartApi = useGet({url: `/cart?update=${update}`, loginType:"login"});

    function countFunc(item,type, amount){
        if(submitChk){
            setSubmitChk(false);

            if (amount === 1 && type === "minus") {
                setSubmitChk(true);
                return false
            }

            let payload = {
                cartId: item,
                type: type,
            };

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: JSON.stringify(payload),
                loginType: "login",
                url: `/cart/amount-modify`,
                success: (data) => {
                    setSubmitChk(true);
                    setUpdate(update + 1)
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setBottomMsgData({
                            text : data.alert||"",
                            chk : bottomMsgData.chk + 1
                        });
                    }
                }
            })
        }
    }

    function handleDelete(cartIds) {
        let formData = '';
        cartIds?.map((cartId, cartIdKey) => {
            if (cartIdKey > 0) {
                formData += '&';
            }
            formData += `cartIds=${cartId}`;
        })

        fatchSet.FatchApi({
            type: "DELETE",
            formDataItem: formData,
            contentType: "application/x-www-form-urlencoded",
            loginType:"login",
            url: `/cart/delete`,
            success: (data) => {
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    // 체크박스 선택
    const handleSingleCheck = (checked, id) => {
        if (checked) {
            setSelectIds(prev => [...prev, id]);
        } else {
            setSelectIds(selectIds.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
            const idArr = [];
            cartApi?.carts?.forEach((el) => {idArr.push(el.id);});
            setSelectIds(idArr);
        }
        else {
            setSelectIds([]);
        }
    }

    function addOrder(){
        if (selectIds?.length > 0) {
            let oldData = [];
            cartApi?.carts?.filter(item => selectIds.includes(item?.id))?.map((cart) => {
                var productPrice = cart?.discountPercent ? getDiscountPrice(cart?.discountPercent, cart?.discountCut, cart.price) : cart?.price
                oldData.push({
                    id: cart?.optionId,
                    name: cart?.productOptionName,
                    productId: cart?.productId,
                    productName: cart?.productName,
                    productImageUrl: cart?.imageUrl,
                    productPrice: productPrice,
                    isRegular: 0,
                    add_price: cart?.discountPercent ? getDiscountPrice(cart?.discountPercent, cart?.discountCut, cart.price) : cart?.price,
                    total_price: (cart?.discountPercent ? getDiscountPrice(cart?.discountPercent, cart?.discountCut, cart.price) * cart?.cartAmount : cart?.price),
                    discount_price: cart?.discountPercent ? getDiscountPrice(cart?.discountPercent, cart?.discountCut, cart.price) : null,
                    discount_basic_price: cart?.price,
                    remain_amount:cart?.amount,
                    qty:cart?.cartAmount
                });
            })

            navigate(`/order`, {state: {type: 'direct', options:oldData, cartIds: selectIds}});
        } else {
            window.errMsg("구매할 제품을 선택해주세요.");
        }
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="장바구니"
                func={() => {navigate(-1)}}
                centerTitle={true}
                homeBtn={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="cartBox">
                    <div className="menuBox">
                        <div className="left">
                            <ChkBox label={''} checkedType={selectIds?.length > 0 && selectIds?.length === cartApi?.carts?.length} func={(e) => { handleAllCheck(e.target.checked) }}/>
                            전체선택
                        </div>
                        <button className="right" onClick={() => { if (selectIds?.length > 0) { handleDelete(selectIds) } }}>선택 삭제</button>
                    </div>
                    {cartApi?.carts?.length > 0 ? cartApi?.carts?.map((cart) => (
                        <div className="cartProductBox">
                            <div className="top">
                                <div className="checkbox">
                                    <ChkBox label={''} checkedType={selectIds?.includes(cart?.id)} func={(e) => handleSingleCheck(e.target.checked, cart.id)}/>
                                </div>
                                <div className="img">
                                    <img src={cart?.imageUrl}/>
                                </div>
                                <div className="contents">
                                    <div className="title">{cart?.productName}</div>
                                    <div className="option">{cart?.productOptionName}</div>
                                    <div className="price">
                                        {/*{cart?.discountPercent && <span>{comFormat(cart?.price)}원</span>} {cart?.discountPercent ? comFormat(Math.round(cart?.price / 100 * (100 - cart?.discountPercent))) : comFormat(cart?.price)}원*/}
                                        {cart?.discountPercent && <span className={"fz-12"}>{comFormat(cart?.price)}원</span>} {cart?.discountPercent ? comFormat(getDiscountPrice(cart?.discountPercent, cart?.discountCut, cart.price)) : comFormat(cart?.price)}원
                                    </div>
                                </div>
                                <button className="close" onClick={() => handleDelete([cart?.id])}>
                                    <img src="/assets/images/basic/grayClose.svg"/>
                                </button>
                            </div>
                            <div className="bottom">
                                <div className="amount">
                                    <CountInput
                                        val={cart?.cartAmount}
                                        qty={null}
                                        optionQty={null}
                                        maxQty={cart?.amount}
                                        func={(val)=>{
                                            countFunc(cart?.id,val,cart?.cartAmount)
                                        }}
                                        excessFunc={()=>{}}
                                    />
                                </div>
                                <div className="price">{comFormat(cart?.totalPrice)}원</div>
                            </div>
                        </div>
                    )) : <div className="emptyBox">
                        장바구니에 담긴 상품이 없습니다.
                    </div>}
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox" style={{ paddingBottom: 90 }}>
                    <div className="orderInfo">
                        <div className="orderTitle">구매 정보</div>
                        <div className="orderContentsBox">
                            <div className="orderContents">
                                <div className="title">상품 금액</div>
                                <div className="contents">{cartApi?.totalPrice ? comFormat(cartApi?.totalPrice) : 0}원</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">배송비</div>
                                <div className="contents">{cartApi?.deliveryPrice ? comFormat(cartApi?.deliveryPrice) : 0}원</div>
                            </div>
                        </div>
                        <div className="orderTotal">
                            <div className="title">총 결제금액</div>
                            <div className="contents">{cartApi?.totalPrice ? comFormat(cartApi?.deliveryPrice + cartApi?.totalPrice) : 0}원</div>
                        </div>
                    </div>
                    <BtnBox boxType="fixed" addClass={`indexUp fixButtonBox`}>
                        <button className="fixButton" onClick={() => handleDelete([])}>장바구니 비우기</button>
                        <button className="fixButton full" onClick={() => addOrder()}>선택한 제품 구매</button>
                    </BtnBox>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Index;