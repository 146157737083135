import React, {useEffect, useState} from "react";
import useGet from "api/useGet";
import {useNavigate, useParams} from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../component/order/detail";
import {InputItemBox} from "../../component/basic/formItems";
import {useDaumPostcodePopup} from "react-daum-postcode";
import * as fatchSet from "../../api/api";
import moment from "moment";

const Present = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();

    const open = useDaumPostcodePopup();

    const [update, setUpdate] =  useState(1);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [originalOrder, setOriginalOrder] =  useState('');
    const [deliveryName, setDeliveryName] =  useState('');
    const [deliveryPhone, setDeliveryPhone] =  useState('');
    const [deliveryAddress, setDeliveryAddress] =  useState('');
    const [deliveryAddressDetail, setDeliveryAddressDetail] =  useState('');
    const [deliveryPostcode, setDeliveryPostcode] =  useState('');

    const orderApi = useGet({url: `/order/present/view?orderId=${id}`, loginType:"login"});

    useEffect(() => {
        if (orderApi) {
            if (orderApi?.message === "만료된 선물입니다") {
                navigate("/present/fail")
            } else {
                if (orderApi?.limitDate <= moment().format("YYYY-MM-DD HH:mm:ss")) {
                    navigate("/present/fail")
                }
                setOriginalOrder(orderApi)
            }
        }
    }, [orderApi]);

    const handleCompleteOrder = (addrItem) => {
        setDeliveryAddress(addrItem?.address)
        setDeliveryPostcode(addrItem?.zonecode)
    };

    const addrShOpen = () => {
        open({ onComplete: handleCompleteOrder });
    };

    function handleSubmit() {
        if ((!deliveryName || !deliveryPhone || !deliveryAddress || !deliveryAddressDetail || !deliveryPostcode)) {
            setBottomMsgData({
                text : "배송지를 입력해주세요",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        let payload = {
            orderId: parseInt(id),
            recipientName: deliveryName,
            recipientPhone: deliveryPhone,
            recipientAddress: deliveryAddress,
            recipientAddressDetail: deliveryAddressDetail,
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/present/delivery-request`,
            success: (result) => {
                navigate('/present/complete')
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="선물 배송 신청"
                centerTitle={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="orderDetailBox">
                    <OrderData products={originalOrder?.orderProducts} orderInfo={originalOrder} present={true}/>
                </div>
                <div className="orderHr"/>
                <div className="inputContentsBox pb-20">
                    <div className="inputTitle">선물 수령자 정보</div>
                    <div className="inputItem">
                        <div className="title">받는 분 성함</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="이름을 입력해주세요."
                                value={deliveryName}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryName(value) }}
                            />
                        </div>
                        <div className="subContents">· 선물을 보낸 상대방이 입력한 성함과 일치해야해요.</div>
                    </div>
                    <div className="inputItem">
                        <div className="title">휴대폰 번호</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="휴대폰 번호를 입력해주세요."
                                value={deliveryPhone}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryPhone(value) }}
                            />
                        </div>
                    </div>
                    <div className="inputItem">
                        <div className="title">배송지</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="주소 검색"
                                value={deliveryAddress}
                                max={null}
                                regexp={null}
                                func={(value)=>{ }}
                                clickInput={(value)=>{addrShOpen("order")}}
                            />
                        </div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="상세 주소 입력"
                                value={deliveryAddressDetail}
                                max={null}
                                regexp={null}
                                func={(value)=>{ setDeliveryAddressDetail(value) }}
                            />
                        </div>
                    </div>
                    <div className="subContents">연락처와 배송지 정보는 선물 받는 분만이 알 수 있어요.<br/>
                        입력하신 정보는 선물 배송 외 다른 용도로 활용되지 않습니다.</div>
                    <button type="button" className={`btn_popup full`} onClick={() => { handleSubmit() }}>배송 신청 하기</button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Present;