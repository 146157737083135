import React, {useCallback, useRef, useState} from "react";
import useGet from "api/useGet";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../../component/order/detail";
import moment from "moment";

const Index = (props) => {
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(1);
    const [isRegular,setIsRegular] =  useState('');
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const orderApi = useGet({url: `/order?isRegular=${isRegular}&update=${update}`, loginType:"login"});

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="주문 내역"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="tabBox">
                    <div className={`tab ${isRegular === '' ? 'active' : ''}`} onClick={() => setIsRegular('')}>전체 주문내역</div>
                    <div className={`tab ${isRegular === '1' ? 'active' : ''}`} onClick={() => setIsRegular('1')}>구독 주문내역</div>
                </div>
                <div className="orderDetailBox">
                    {orderApi?.length > 0 ? orderApi?.map((order, orderKey) => (
                        <>
                            <div className="orderNumber orderDate">
                                {order?.isRegular === 1 && (<span className="label">{order?.cycle}회차</span>)}
                                {(orderKey === 0 || moment(order?.createAt).format("YYYY-MM-DD") !== moment(order[orderKey - 1]?.createAt).format("YYYY-MM-DD")) && (<>{moment(order?.createAt).format("YYYY-MM-DD")}</>)}
                            </div>
                            <OrderData products={order?.orderProducts} orderInfo={order} order={true} setBottomMsgData={setBottomMsgData} setUpdate={setUpdate}/>
                        </>
                    )) : (
                        <div className="emptyBox">
                            주문한 내역이 없습니다.
                        </div>
                    )}
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Index;