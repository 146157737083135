export default (discountPercent, discountCut, price) => {
    if (discountPercent && discountCut && price) {
        const discountPrice = (price / 100) * discountPercent;
        const productPrice = price - discountPrice;

        return (Math.floor(productPrice/Math.pow(10, discountCut))) * Math.pow(10, discountCut)

    } else {
        return '';
    }
}
