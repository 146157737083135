import React, { useState } from "react";
import useGet from "api/useGet";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {comFormat} from "../../js/function";
import moment from "moment";

const Index = (props) => {
    const navigate = useNavigate();

    const pointApi = useGet({url: `/point/history`, loginType:"login"});

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="포인트 내역"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                homeBtn={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="listSummaryBox">
                    <div className="title">보유포인트</div>
                    <div className="contents">{comFormat(pointApi?.total_point)} P</div>
                </div>
                <div className="listBox">
                    {pointApi?.point_history?.map((point) => (
                        <div className="listItem">
                            <div className="contents">
                                <div className="title">{point?.reason}</div>
                                <div className={point?.type === 2 ? "minusPoint" : 'point'}>{(point?.type === 2 ? '-' : '') + comFormat(point?.point)} P</div>
                            </div>
                            <div className="contents">
                                <div className="date">{moment(point?.createAt).format("YYYY-MM-DD HH:mm")}</div>
                                <div className="type">{point?.type === 1 ? '적립' : '차감'}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </ContentsSection>
        </>
    );
};

export default Index;