import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

const Login = (props) => {
    const navigate = useNavigate();

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="loginBox">
                    <div className="loginSwiperBox">
                        <Swiper
                            modules={[Autoplay]}
                            slidesPerView={1}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            style={{width: "100%", height: "40vh"}}>
                            <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img className="image" src="/assets/images/login/login_carousel1_ios.png"
                                     style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
                            </SwiperSlide>
                            <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img className="image" src="/assets/images/login/login_carousel2_ios.png"
                                     style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
                            </SwiperSlide>
                            <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img className="image" src="/assets/images/login/login_carousel3_ios.png"
                                     style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
                            </SwiperSlide>
                            <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img className="image" src="/assets/images/login/login_carousel4_ios.png"
                                     style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
                            </SwiperSlide>
                            <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img className="image" src="/assets/images/login/login_carousel5_ios.png"
                                     style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="snsLoginBox">
                        <button onClick={() => {
                            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`
                        }}><img src="/assets/images/basic/kakao_login.svg"/></button>
                        <button><img src="/assets/images/basic/naver_login.svg"/></button>
                        <button><img src="/assets/images/basic/google_login.svg"/></button>
                        <button><img src="/assets/images/basic/apple_login.svg"/></button>
                    </div>
                    <div className="phoneLogin">
                        <a href="/login/phone">휴대폰 번호로 로그인 ></a>
                    </div>
                    <div className="findAccount">
                        <a href="/find/account">가입한 계정이 기억나지 않아요</a>
                    </div>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Login;