import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router/Router';

import "./css/quill.snow.css";
import "./css/basic.css";
import "./css/popup.css";
import "./css/btn.css";
import "./css/style.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);