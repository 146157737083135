import React from "react";
import { useState, useRef } from "react";
import { useCallback } from "react";
import {BottomErrMsg, Popup} from "../basic/popup";
import * as fatchSet from "../../api/api";

function ReviewData(data){
    const box = useRef(null);

    const [bottomOpen,setBottomOpen] = useState(false);
    const [bottomReportOpen,setBottomReportOpen] = useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setBottomOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [bottomOpen]
    );

    const reportCloseClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setBottomReportOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [bottomOpen]
    );

    const onClickEvn = () =>{
        setBottomOpen(!bottomOpen);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    const onClickReportEvn = () =>{
        setBottomReportOpen(!bottomReportOpen);
        setTimeout(() => {
            window.addEventListener("click",reportCloseClick);
        }, 10);
    }

    function handleBlock() {
        let payload = {
            blockUserId: data?.review?.userId
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/review/user-block`,
            success: (result) => {
                setPopupData({
                    addClass:null,
                    title:"작성자 차단 완료",
                    text:`작성자를 차단 완료했습니다.<br/>해당 회원의 리뷰는 앞으로 노출되지 않습니다.`,
                    closeType:true,
                    closeFunc:(e) => {setPopupData(null); window.location.reload();},
                    btnFunc0:(e)=>{setPopupData(null); window.location.reload();},
                    btn0:"확인",
                    btn0Type:"",
                });
            },
            err: (result) => {
                if(result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleReport(selectReason) {
        let payload = {
            reviewId: data?.review?.id,
            reason: selectReason
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/review/report`,
            success: (result) => {
                setPopupData({
                    addClass:null,
                    title:"작성자 신고 완료",
                    text:`작성자 리뷰를 신고 완료했습니다.<br/>해당 회원의 리뷰는 앞으로 노출되지 않습니다.`,
                    closeType:true,
                    closeFunc:(e) => {setPopupData(null); window.location.reload();},
                    btnFunc0:(e)=>{setPopupData(null); window.location.reload();},
                    btn0:"확인",
                    btn0Type:"",
                });
                setBottomReportOpen(false);
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <div className="reviewItem">
            <div className="reviewTitle">
                <span>{data?.review?.nickname}</span>
                <button className={"plusBtn"} onClick={() => setBottomOpen(!bottomOpen)}>
                    <img src="/assets/images/basic/menu.svg"/>
                </button>
            </div>
            <div className="reviewSubTitle">
                <div className="reviewDate">{data?.review?.createAt}</div>
                <div className="reviewStar">
                    <div className="productReviewText">{data?.review?.grade}</div>
                    <div className="productReviewStarBox">
                        {Array.from({ length: data?.review?.grade }).map((_, idx) => (
                            <img src="/assets/images/icon/starOn.svg"/>
                        ))}
                        {Array.from({ length: 5 - data?.review?.grade }).map((_, idx) => (
                            <img src="/assets/images/icon/starOff.svg"/>
                        ))}
                    </div>
                </div>
            </div>
            <div className="reviewContents">
                {data?.review?.text}
            </div>
            <div className="reviewImageBox">
                {data?.review?.images?.map((image) => (
                    <img src={image}/>
                ))}
            </div>
            <div className={`bottom_optionBoxWrapper ${bottomOpen ? "active" : ""}`} ref={box}>
                <div className={`bottom_optionBox ${bottomOpen ? "active" : ""}`}>
                    <div className={`bottom_option`}>
                        <div className="title">리뷰 더보기</div>
                        <div className={`bottom_option_close`} onClick={onClickEvn}>
                            <img src="/assets/images/basic/close.svg"/>
                        </div>
                    </div>
                    <button type="button" className={`bottom_option`} onClick={()=>{ setBottomOpen(false); setBottomReportOpen(true) }}>
                        <div className="bottom_optionContents">작성자 신고</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                    <button type="button" className={`bottom_option`} onClick={()=>{
                        setBottomOpen(false);
                        setPopupData({
                            addClass:null,
                            title:"작성자 차단",
                            text:`작성자의 리뷰를 차단하시겠습니까?<br/>해당 회원의 리뷰는 앞으로 노출되지 않습니다.`,
                            closeType:true,
                            closeFunc:(e) => {setPopupData(null)},
                            btnFunc0:(e)=>{ handleBlock() },
                            btn0:"차단하기",
                            btn0Type:"",
                            btn1:"취소",
                            btn1Type:"line",
                            btnFunc1:()=>{setPopupData(null);}
                        });
                    }}>
                        <div className="bottom_optionContents">작성자 차단</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                </div>
            </div>
            <div className={`bottom_optionBoxWrapper ${bottomReportOpen ? "active" : ""}`} onClick={onClickReportEvn} ref={box}>
                <div className={`bottom_optionBox ${bottomReportOpen ? "active" : ""}`}>
                    <div className={`bottom_option`}>
                        <div className="title">작성자 신고 사유 선택</div>
                        <div className={`bottom_option_close`} onClick={onClickReportEvn}>
                            <img src="/assets/images/basic/close.svg"/>
                        </div>
                    </div>
                    <button type="button" className={`bottom_option`} onClick={()=>{ handleReport('제품과 관계없는 내용') }}>
                        <div className="bottom_optionContents">제품과 관계없는 내용</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                    <button type="button" className={`bottom_option`} onClick={()=>{ handleReport('개인 및 판매자의 상업적 홍보') }}>
                        <div className="bottom_optionContents">개인 및 판매자의 상업적 홍보</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                    <button type="button" className={`bottom_option`} onClick={()=>{ handleReport('개인정보 누출 위험') }}>
                        <div className="bottom_optionContents">개인정보 누출 위험</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                    <button type="button" className={`bottom_option`} onClick={()=>{ handleReport('비방 및 욕설') }}>
                        <div className="bottom_optionContents">비방 및 욕설</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                    <button type="button" className={`bottom_option`} onClick={()=>{ handleReport('잘못된 정보') }}>
                        <div className="bottom_optionContents">잘못된 정보</div>
                        <img className="bottom_optionCheck" src="/assets/images/basic/selectCheck.svg"/>
                    </button>
                </div>
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup data={popupData}/>
        </div>
    );
}

export {ReviewData};