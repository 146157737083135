import React, {useCallback, useEffect, useRef, useState} from "react";
import useGet from "api/useGet";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../../component/order/detail";
import {comFormat, PhoneSetting} from "../../../js/function";
import moment from "moment/moment";
import * as fatchSet from "../../../api/api";

const Detail = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();
    const locationSearch = useLocation().search;

    const prams = locationSearch.split('&') ? locationSearch.split('&') : [];
    const orderProductId = prams[0] ? prams[0].split('?orderProductId=')[1] : '';
    const type = prams[1] ? prams[1].split('type=')[1] : 'default';

    const [update, setUpdate] =  useState(1);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);
    const [popupData2,setPopupData2] =  useState(null);
    const [originalOrder, setOriginalOrder] =  useState('');

    const orderApi = useGet({url: `/order/detail?orderId=${id}${orderProductId !== '' ? `&orderProductId=${orderProductId}` : ''}${type !== '' ? `&type=${type}` : ''}`, loginType:"login"});

    useEffect(()=>{
        window.Kakao.cleanup();
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_KEY);
        }
    },[]);

    useEffect(() => {
        if (orderApi) {
            setOriginalOrder(orderApi)
        }
    }, [orderApi]);

    function handleStatus(id, status, type = 'orderProduct') {
        let payload = {};
        if (type === 'orderProduct') {
            payload = {
                status: status,
                orderProductId: id,
            };
        } else {
            payload = {
                status: status,
                orderId: id,
            };
        }

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/status-modify`,
            success: (result) => {
                setUpdate(update + 1)
                if (status === 10) {
                    setPopupData(null)
                    setPopupData2({
                        addClass:null,
                        title:"주문 취소 신청 완료",
                        text:`주문 취소 신청이 완료되었습니다.<br/>결제가 진행된 주문은 영업일 기준 최대 3~5일 소요되며 취소가 지연되실 경우 결제하신 카드사로 문의해주세요.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{setPopupData2(null)},
                        btn0:"닫기",
                        btn0Type:"line",
                        confirm:true
                    });
                } else if (status === 5) {
                    setPopupData(null)
                    setPopupData2({
                        addClass:null,
                        title:"리뷰 작성 안내",
                        text:`구매해 주셔서 감사합니다!<br/>지금 바로 리뷰를 작성해보시겠어요?<br/>리뷰 작성 시 소정의 포인트를 지급드려요.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{setPopupData2(null);navigate(`/review/register/${1}`)},
                        btn0:"리뷰 작성",
                        btn0Type:"",
                        btn1:"닫기",
                        btn1Type:"line",
                        btnFunc1:(e)=>{setPopupData2(null)},
                        confirm:true
                    });
                }
            },
            err: (result) => {
                if(result.data || result.alert){
                    setBottomMsgData({
                        text : result.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleRegularCancel() {
        let payload = {
            orderId: originalOrder?.id,
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/order/regular/cancel`,
            success: (data) => {
                const result = JSON.parse(data);
                setPopupData(null)
                if (result?.orderStatus !== 2 || result?.orderStatus !== 3) {
                    setPopupData2({
                        addClass:null,
                        title:"구독 종료 완료",
                        text:`구독 종료가 완료되었습니다.<br/>이용해주셔서 감사합니다.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{navigate('/mypage/order')},
                        btn0:"확인",
                        btn0Type:"",
                        confirm:true
                    });

                } else {
                    setPopupData2({
                        addClass:null,
                        title:"구독 종료 예정",
                        text:`배송중이거나, 배송 준비중 상태의 상품이 있어 구독 종료가 예약되었습니다.<br/>다음 결제일인 ${result?.schedulePaymentDate}부터 결제 및 배송이 진행되지 않습니다.`,
                        closeType:true,
                        closeFunc:(e) => {setPopupData2(null)},
                        btnFunc0:(e)=>{setPopupData2(null)},
                        btn0:"확인",
                        btn0Type:"",
                        confirm:true
                    });
                }
            },
            err: (result) => {
                if(result.data || result.message){
                    setBottomMsgData({
                        text : result.message||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                headTitle="주문 내역 상세"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="orderDetailBox">
                    <div className="orderNumber">{originalOrder?.orderNumber}</div>
                    {originalOrder?.orderProducts?.map((orderProduct) => (
                        <OrderData products={[orderProduct]} orderInfo={originalOrder} orderDetail={true} setUpdate={setUpdate}/>
                    ))}
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox">
                    <div className="orderInfo">
                        <div className="orderTitle">배송 정보</div>
                        <div className="orderContentsBox">
                            <div className="orderContents">
                                <div className="title">이름</div>
                                <div className="contents">{originalOrder?.recipientName ? originalOrder?.recipientName : '-'}</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">휴대폰 번호</div>
                                <div className="contents">{originalOrder?.recipientPhone ? PhoneSetting(originalOrder?.recipientPhone) : '-'}</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">배송지</div>
                                <div className="contents">{!originalOrder?.recipientAddress && !originalOrder?.recipientAddressDetail ? '-' : originalOrder?.recipientAddress + ' ' + originalOrder?.recipientAddressDetail}</div>
                            </div>
                        </div>
                    </div>
                    {originalOrder?.isPresent === 1 && (<div className="subContents">연락처와 배송지 정보는 선물 받는 분이 직접 입력해요.</div>)}
                </div>
                <div className="orderHr"/>
                <div className="orderDetailBox pb-20">
                    <div className="orderInfo">
                        <div className="orderTitle">구매 정보</div>
                        <div className="orderContentsBox">
                            {originalOrder?.isRegular === 1 && (
                                <div className="orderContents">
                                    <div className="title">배송받는 주기</div>
                                    <div className="contents">매 월 {originalOrder?.deliveryDay}일</div>
                                </div>
                            )}
                            <div className="orderContents">
                                <div className="title">상품 금액</div>
                                <div className="contents">{comFormat(originalOrder?.paymentPrice - originalOrder?.deliveryPrice - originalOrder?.usePoint)}원</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">배송비</div>
                                <div className="contents">{comFormat(originalOrder?.deliveryPrice)}원</div>
                            </div>
                            <div className="orderContents">
                                <div className="title">포인트 할인</div>
                                <div className="contents">- {comFormat(originalOrder?.usePoint)}원</div>
                            </div>
                        </div>
                        <div className="orderTotal">
                            <div className="title">총 결제금액</div>
                            <div className="contents">{comFormat(originalOrder?.paymentPrice)}원</div>
                        </div>
                    </div>
                    {((originalOrder?.status === 0 || originalOrder?.status === 1) && originalOrder?.isRegular !== 1) && (
                        <button type="button" className={`btn_popup`} onClick={() => {
                            setPopupData({
                                addClass:null,
                                title:"주문 취소 신청",
                                text:`주문을 취소하시겠습니까?<br/>취소한 주문은 복구가 불가합니다.`,
                                closeType:true,
                                closeFunc:(e) => {setPopupData(null)},
                                btnFunc0:(e)=>{
                                    handleStatus(originalOrder?.id, 10, 'order')
                                },
                                btn0:"주문 취소",
                                btn0Type:"",
                                btn1:"취소",
                                btn1Type:"line",
                                btnFunc1:()=>{setPopupData(null);}
                            });
                        }}>주문 취소 신청</button>
                    )}

                    {(originalOrder?.isRegular === 1 && originalOrder?.regularStatus !== 10) && (
                        <button type="button" className={`btn_popup`} onClick={() => {
                            setPopupData({
                                addClass:null,
                                title:"구독 종료 안내",
                                text:`구독을 종료하시겠습니까?<br/>종료하시는 시점에 종료하고자 하시는 주문이 배송중이거나, 배송 준비중 상태일 경우 다음 회차의 결제부터 구독 종료됩니다.`,
                                closeType:true,
                                closeFunc:(e) => {setPopupData(null)},
                                btnFunc0:(e)=>{handleRegularCancel()},
                                btn0:"구독 종료",
                                btn0Type:"",
                                btn1:"닫기",
                                btn1Type:"line",
                                btnFunc1:()=>{setPopupData(null);}
                            });
                        }}>구독 종료</button>
                    )}
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup data={popupData}/>
            <Popup data={popupData2}/>
        </>
    );
};

export default Detail;